<template>
  <v-container fluid>
    <TableViewComponent
      title="Table ticketing > périmètre"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un périmètre"
      itemLabel="périmètre"
      itemsLabel="périmètres"
      :items="entities"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce périmètre ?"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    ></TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import { TicketingPerimeterService } from "@/service/conf/ticketing_perimeter_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableTicketingPerimeter",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les données. chaque élément doit avoir un id, ainsi que les fonctions edit, view, delete */
      entities: [],

      /**le service d'accès */
      service: null,
    };
  },
  methods: {
    /** On part vers la page d'ajout*/
    onAddElement() { 
      this.$router.push(routes.ticketingPerimeter.add.path);
    },

    async load() {
      try {
        this.loading = true;

        this.entities = [];

        let entities = await this.service.getAll();

        entities.forEach((e) => {
          let entity = JSON.parse(JSON.stringify(e));
          entity.view = () => {
            this.$router.push(routes.ticketingPerimeter.view.root + "/" + entity.id);
          };

          //action de modification
          entity.edit = () => {
            this.$router.push(routes.ticketingPerimeter.edit.root + "/" + entity.id);
          };

          entity.delete = async () => {
            try {
              await this.service.delete(entity.id);
              this.load();
            } catch (error) {
              console.error(error);
              this.addErrorToSnackbar(
                "suppression du périmètre : " +
                  (exceptions.toMessage(error) || "problème technique")
              );
            }
          };

          this.entities.push(entity);
        });

      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Label",
          align: "start",
          sortable: true,
          value: "label",
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditConf];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditConf, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadConf];
    },
  },
  mounted() {
    //on instancie les services
    this.service = new TicketingPerimeterService(this.$api.getTicketingPerimeterApi());

    this.load();
  },
  
};
</script>

<style>
</style>